<template>
 <div>
    <div>
      <div class="row align-items-center justify-content-center text-center text-justify h-90" style="height: 256px;margin-top: 10%;">

  <div class="col-12">
        <span class="landingpage-sample02-text02 WebBM text-center text-justify">
          Elite community reward platform</span>
      
    </div>
    <div class="col-12">
      <span class="landingpage-sample02-text WebHL">
        <span>Welcome Emperors</span>
      </span>
    </div>
  <div class="col-12 mb-2">
    <span class="landingpage-sample02-text04 WebBM">
        <span>
          This platform is built to reward our community and incentivize them to
          engage with us and help us reach our goals.
        </span>
      </span>
    </div>
    <div class="col-12">
      <b-button @click="openmodal=true" class="landingpage-sample02-btn-get-started">
       
       <span class="landingpage-sample02-text06 WebBS">
         <span>Get Started</span>
       </span>
     </b-button>
    </div>

</div>
 
      
     


       
    
       
       
    </div>
     <b-modal
     v-model="openmodal"
     hide-header
     hide-footer
     centered
     >
    <b-row class="mt-1 mb-1">
      <b-col cols="12">
        <div class="text-center">
          <b-img
          :src="require('@/assets/images/illustration/logo.png')"
      rounded="circle"
      alt="Circle image"
      class="d-inline-block mr-1 mb-1"
    />
    </div>
      </b-col>
      <b-col cols="12">
          <div class="text-center">
        <h2 class="font-weight-bolder" style="background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
        -webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;
        text-fill-color: transparent;">Welcome EliteRewards</h2>
          </div>
      </b-col>
      <b-col cols="12">
        <div class="text-center mt1">
        <span>Connect with your discord and start earning rewards</span>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="text-center mt-2">
        <b-button
        block
        pill
        :href="validationForm()"
        class="backgroundBtn"
        style="padding:18px 80px;"
        >
          Connect Discord
        </b-button>
        </div>
      </b-col>
    </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, 
  BCardTitle, BImg, BForm, BButton,BModal,BCard
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters, mapState } from "vuex";
import queryString from "@/utils/queryString";
import config from "@/config";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BModal,BCard
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      openmodal:false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
       rawmujj: ' ',
    }
  },
  computed: {
    
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/playground_assets/gobletgray.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm: function() {

      const loginParams = {
        client_id: "1041092168573534268",
        redirect_uri: window.location.href+"/discord",
        response_type: "token",
        scope: "identify guilds"
      };
      return `https://discordapp.com/api/oauth2/authorize${queryString(loginParams)}`;
     }
  
      }
    }

</script>

<style>
.backgroundBtn{
background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
 border: transparent;
}
.WebHL {
    font-size: 56px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-stretch: normal;
}
.WebBS {
    font-size: 14px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400px;
    font-stretch: normal;
}
.WebBM {
    font-size: 16px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400px;
    font-stretch: normal;
}

.landingpage-sample02-landingpage-sample02 {
  width: 100%;
  height: 898px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 1);
}
.landingpage-sample02-landingpage-b-g {
  top: 0px;
  left: 0px;
  width: 1512px;
  height: 898px;
  position: absolute;
  border-color: transparent;
}

.landingpage-sample02-text14 {
  top: 40px;
  left: 64px;
  color: var(--dl-color-default-grey);
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 16px;
  font-stretch: normal;
  text-decoration: none;
}

.landingpage-sample02-logo1 {
  top: 92px;
  left: 27px;
  width: 18px;
  height: 16px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.landingpage-sample02-text20 {
  top: 16px;
  left: 112px;
  color: rgba(140, 224, 184, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: Semi Bold;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 32px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.landingpage-sample02-text21 {
  color: var(--dl-color-default-otherc2primaryboarder);
  font-weight: 700;
}

.landingpage-sample02-text23 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.landingpage-sample02-image1 {
  top: 16px;
  left: 80px;
  width: 32px;
  height: 32px;
  position: absolute;
  border-color: transparent;
}
.landingpage-sample02-text25 {
  color: var(--dl-color-default-oneliteprimary);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  margin-right: 0;
  margin-bottom: 0;
}

.landingpage-sample02-text27 {
  color: var(--dl-color-default-oneliteprimary);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  margin-right: 0;
  margin-bottom: 0;
}

.landingpage-sample02-text {
  
  color: rgba(255, 255, 255, 1);
  height: auto;
  align-self: center;
  text-align: center;
  line-height: 64px;
}

.landingpage-sample02-text02 {
  color:#8CE0B8;
  height: auto;
  align-self: center;
  text-align: center;
  line-height: 24px;
  margin-right: 0;
  margin-bottom: 0;
}
.landingpage-sample02-text04 {
  color: #ffd;
  width: 647px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 24px;
}

.landingpage-sample02-btn-get-started{
  border: transparent;
  justify-content: center;
  padding:18px 80px;
  border-radius:40px ;
  background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
  background-image: linear-gradient(NaNdeg, rgba(163, 135, 255, 0.800000011920929) 0%, rgba(73, 43, 173, 0.8299999833106995) 53%, rgba(39, 8, 142, 1) 100%);
}
.landingpage-sample02-text06 {
  color: rgba(255, 255, 255, 1);
  
}

@media (max-width: 991.98px) { 
  body.dark-layout{
  background: url('../assets/images/illustration/loginm.svg') no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #0E1116;
}


 }

@media (min-width: 992px) {
  body.dark-layout{
  background: url('../assets/images/illustration/login.svg') no-repeat;;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-color: #0E1116;
}


}
</style>

